import { createWebHistory, createRouter } from "vue-router";
import About from "@/components/About.vue";
import BrochureForm from "@/components/BrochureForm.vue";
import Home from "@/components/Home.vue";
//import Signup from "@/components/Signup.vue"
import NewEvent from "@/components/NewEvent.vue"
import OrganizerEvents from "@/components/OrganizerEvents.vue"
import ParticipantStores from "@/components/ParticipantStores.vue"
import RunningEvent from "@/components/RunningEvent.vue"
import OpenStore from "@/components/OpenStore.vue"
import PublicStore from "@/components/PublicStore.vue"
import PublicEvent from "@/components/PublicEvent.vue"
import DashboardOptions from "@/components/DashboardOptions.vue"
import SignupParticipant from "@/components/SignupParticipant.vue"
import EventDetail from "@/components/EventDetail.vue"
import EventDetailEnded from "@/components/EventDetailEnded.vue"
import StoreDetail from "@/components/StoreDetail.vue"
import Login from "@/components/Login.vue"
import MobileLogin from "@/components/MobileLogin.vue"
import Cart from "@/components/Cart.vue"
import Settings from "@/components/Settings.vue"
import ForgotPassword from "@/components/ForgotPassword.vue"
import ResetPassword from "@/components/ResetPassword.vue"
import Checkout from "@/components/Checkout.vue"
import OrderComplete from "@/components/OrderComplete.vue"
import TermsOfUse from "@/components/TermsOfUse.vue"
import PrivacyPolicy from "@/components/PrivacyPolicy.vue"
import VirtualFundraising from "@/components/VirtualFundraising.vue"
import Help from "@/components/Help.vue"
import DraftEvent from "@/components/DraftEvent.vue"

const routes = [
    {
        path: "/Help",
        name: "Help",
        component: Help,
    },
    {
        path: "/About",
        name: "About",
        component: About,
    },
    {
        path: "/BrochureForm",
        name: "BrochureForm",
        component: BrochureForm,
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },    
    //{
    //    path: "/Signup",
    //    name: "Signup",
    //    component: Signup,
    //}, 
    {
        path: "/NewEvent",
        name: "NewEvent",
        component: NewEvent,
    }, 
    //{
    //    path: "/Login",
    //    name: "Login",
    //    component: MobileLogin,
    //},
    {
        path: "/Login/:signup",
        name: "Login",
        component: MobileLogin,
    },
    {
        path: "/o/dashboard",
        name: "OrganizerEvents",
        component: OrganizerEvents,
    },
    {
        path: "/p/dashboard",
        name: "ParticipantStores",
        component: ParticipantStores,
    },
    {
        path: "/o/re",
        name: "RunningEventDasboard",
        component: RunningEvent,
    },
    {
        path: "/p/os",
        name: "OpenStoreDasboard",
        component: OpenStore,
    },
    {
        path: "/dashboard/options",
        name: "DashboardOptions",
        component: DashboardOptions,
    },
    {
        path: "/event/detail/:id",
        name: "EventDetail",
        component: EventDetail,
    },
    {
        path: "/event/draft/:id",
        name: "DraftEvent",
        component: DraftEvent,
    },
    {
        path: "/event/ended/:id",
        name: "EventDetailEnded",
        component: EventDetailEnded,
    },
    {
        path: "/event/running/",
        name: "RunningEvent",
        component: RunningEvent,
    },
    {
        path: "/store/open/:id",
        name: "OpenStore",
        component: OpenStore,
    },
    {
        path: "/p/signup/:code",
        name: "SignupParticipant",
        component: SignupParticipant,
    },
    {
        path: "/p/signup/:mobile",
        name: "SignupParticipant",
        component: SignupParticipant,
    },
    {
        path: "/store/public/:code",
        name: "PublicStore",
        component: PublicStore,
    },
    {
        path: "/event/public/:code",
        name: "PublicEvent",
        component: PublicEvent,
    },
    {
        path: "/store/detail/:id",
        name: "StoreDetail",
        component: StoreDetail,
    },
    {
        path: "/cart",
        name: "Cart",
        component: Cart, 
    },
    {
        path: "/checkout",
        name: "Checkout",
        component: Checkout, 
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/forgorpassword",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/ordercomplete",
        name: "OrderComplete",
        component: OrderComplete,
    },
    {
        path: "/termsofuse",
        name: "TermsOfUse",
        component: TermsOfUse,
    },
    {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/VirtualFundraising",
        name: "VirtualFundraising",
        component: VirtualFundraising,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Home",
        component: () => import ("@/components/Home.vue"),
    },    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
       // document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        var element = document.getElementById('app');
        var headerOffset = 50;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
});

export default router;