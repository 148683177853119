import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from "../router"; // our vue router instance
import store from "@/store"
import { deleteCookie, getCookieObject, setCookieObject } from "../assets/js/app.js";

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        refresh_token: "",
        access_token: "",
        loggedInUser: {},
        isAuthenticated: false
    },
    mutations: {
        setRefreshToken: function (state, refreshToken) {
            state.refresh_token = refreshToken;
        },
        setAccessToken: function (state, accessToken) {
            state.access_token = accessToken;
        },
        // sets state with user information and toggles 
        // isAuthenticated from false to true
        setLoggedInUser: function (state, user) {
            state.loggedInUser = user;
            state.isAuthenticated = true;
        },
        // delete all auth and user information from the state
        clearUserData: function (state) {
            state.refresh_token = "";
            state.access_token = "";
            state.loggedInUser = {};
            state.isAuthenticated = false;
            state.storeCode = "";
        },
        addToCart: function (state, product) {
            product.storeCode = state.storeCode;
            let cart = getCookieObject('ct');
            if (cart == null)
                cart = { total: 0, products: [] };
            else
                cart.total = 0;
            //if product selected before then increment it
            //let existProduct = cart.products.filter(p => p.id == product.id);
            //if (existProduct.length > 0) {
            //    existProduct = existProduct[0];
            //    existProduct.price = parseFloat(existProduct.price);
            //    existProduct.count = parseInt(existProduct.count);
            //    existProduct.count += parseInt(product.count);
            //    existProduct.total = existProduct.count * existProduct.price;
            //    //loop over bags in the product and increment what is exist
            //    product.bags.forEach(bag => {
            //        let existBag = existProduct.bags.filter(b => b.id == bag.id);
            //        if (existBag.length == 0) {
            //            existProduct.bags.push(bag);
            //        }
            //        else {
            //            existBag[0].count = existBag[0].count + bag.count;
            //            existBag[0].total = existBag[0].total + bag.total;
            //        }
            //    });
            //}
            //else
            cart.products.push(product);            

            cart.products.forEach(p => {
                cart.total += parseFloat(p.total);
            });
            cart.total = cart.total.toFixed(2);
            setCookieObject('ct', cart);
            state.cart = cart;
        },
        setCart: function(state,cart){
            setCookieObject('ct', cart);
            state.cart = cart;
        },
        clearCart: function (state) {
            deleteCookie('ct');
            state.cart = null;
        }
    },
    actions: {
        login: async ({ commit }, payload) => {
            commit("setRefreshToken", payload.data.refreshToken);
            commit("setAccessToken", payload.data.jwtToken);
            commit("setLoggedInUser", payload.data.user);
            store.dispatch("routeUser");
        },
        login2: async ({ commit }, payload) => {
            commit("setRefreshToken", payload.data.refreshToken);
            commit("setAccessToken", payload.data.jwtToken);
            commit("setLoggedInUser", payload.data.user);
        },
        logout: ({ commit }) => {
            commit("clearUserData");
            router.push("/login");
        },
        refreshToken: async ({ state, commit }) => {
            const refreshUrl = "/api/users/refresh-token/";
            try {
                await axios
                    .post(refreshUrl, { refreshToken: state.refresh_token })
                    .then(response => {
                        if (response.status === 200) {
                            commit("setAccessToken", response.data.jwtToken);
                            commit("setRefreshToken", response.data.refreshToken);
                        }
                    });
            } catch (e) {
                console.log(e.response);
            }
        },
        routeUser: ({ state,commit }) => {
            if (state.isAuthenticated===true) {
                //if (state.loggedInUser.status === "o/e")
                //    router.push("/o/dashboard");
                //else if (state.loggedInUser.status === "p/s")
                //    router.push("/p/dashboard");
                //else
                    router.push("/dashboard/options");
            }
            else
                router.push("/");
        },
        setCompletedOrder: ({ state }, payload) => {
            state.completeOrder = payload;
            router.push("/ordercomplete");
        },
        addToCart: ({ commit }, payload) => {
            commit("addToCart", payload);
            router.push("/Cart");
        },
        setCart: ({ commit }, payload) => {
            commit("setCart", payload);            
        },
        clearCart: ({ commit }, payload) => {
            commit("clearCart");            
        },
        setStoreCode: ({ state }, payload) => {
            state.storeCode = payload;
        }
    },
    getters: {
        loggedInUser: state => state.loggedInUser,
        currentUserDetail: state => state.loggedInUser,
        hasRunningEvent: state => state.loggedInUser.runningEventId != null,
        runningEventStateDate: state => state.loggedInUser.runningEventStartDate,
        runningEventEndDate: state => state.loggedInUser.runningEventEndDate,
        isAuthenticated: state => state.isAuthenticated,
        accessToken: state => state.access_token,
        refreshToken: state => state.refresh_token,
        cart: state => state.cart,
        storeCode: state => state.storeCode,
        completeOrder: state => state.completeOrder
    }
});
