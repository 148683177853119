import axios from "axios"

const facebookAppId = 413828523668223;

export function initFacebookSdk() {
    return new Promise(resolve => {

        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v12.0' // this is facebook graph api version v12.0 is currently the highest..
            });
                     
            // auto authenticate with the api if already logged in with facebook
            window.FB.getLoginStatus(async ({ authResponse }) => {
                if (authResponse) {
                    await axios.post("/api/users/facebook-authenticate", { accessToken: authResponse.accessToken });
                    resolve();
                } else {
                    resolve();
                }
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            //js.src = "https://connect.facebook.net/en_US/all.js";
            js.src = "https://connect.facebook.net/en_US/sdk.js#version=v12.0";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
       
    });
}